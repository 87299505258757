import React from 'react';
import '../../pages/About/AboutPage.css'
import { Link } from 'react-router-dom';
import trresume from '../../paperwork/resume/tr-resume.pdf';
import gresume from '../../paperwork/resume/g-resume.pdf';
import { ButtomGet } from '../ButtomGet/ButtomGet';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';
/* Img */

import Theme from '../../components/DarkMode/DarkMode';
import Drs from '../../img/home2_dark.gif'
import Lrs from '../../img/home.gif'

const About = () => (
    <section className="sobre-mi" id="sobre-mi">
        <h2 className="heading">
            <FormattedMessage
                id='about'
                defaultMessage='About me'
            />
        </h2>

        <div className="row container">
            <div className="columns" data-aos="fade-right" data-aos-delay="300">
                <h3>
                    <FormattedMessage
                        id='im'
                        defaultMessage='who I am'
                    />
                </h3>
                <div className="sobre-mi-container">
                <div className="sobre-mi-img-container">
              <img src={Lrs} alt="" className="sobre-mi-img" />
              
              <div className='btns_positions'>
                    <a href={trresume} target="_blank" rel="noopener noreferrer" download="cv.pdf" className="btn-codigo cv buttonDownload btn-p1">
                         <FormattedMessage
                            id='btn-tr-Resume'
                            defaultMessage='Turkey Resume'
                         />
                    </a>
                    <a href={gresume} target="_blank" rel="noopener noreferrer" download="cv.pdf" className="btn-codigo cv buttonDownload btn-p2">
                         <FormattedMessage
                            id='btn-g-Resume'
                            defaultMessage='Global Resume'
                         />
                    </a>
                    </div>

           </div>
            </div>
                
            
                <h4>
                <br></br>
                    <FormattedMessage
                        id='description'
                        defaultMessage='My name is Hüsnü KAYA and I am a Full Stack Java Developer.'
                    />
                </h4>
                <p>
                    <FormattedMessage
                        id='my-description'
                        defaultMessage=''
                    />
                </p>
                <ul>
                  
                    <li>
                        <p>
                            <span>
                                Hobbies:{" "}
                            </span>
                                 Chess, Football and Programming
                        </p>
                    </li>
                    <li>
                        <p href="mailto:yusuftekmil.yt@outlook.com.tr">
                            <span>Email:
                                </span>
                                 husnukaya41@gmail.com
                            </p>
                        
                    </li>
                    <li>
                        <p>
                            <span>
                                <FormattedMessage
                                    id='from'
                                    defaultMessage='From:'
                                />
                            </span>
                             Golcuk,Turkey
                        </p>
                    </li>
                </ul>
                <div className="mas-info">
                    
                    <div className='mas-info-btn'>
                    <Link to="/about">
                        <ButtomGet/>
                    </Link>
                    </div>
                </div>
            </div>
            <div className="columns col-skill" data-aos="fade-left" data-aos-delay="650">
                <h3>
                    <FormattedMessage
                        id='skills'
                        defaultMessage='Skills'
                    />
                    </h3>
                <h4>
                    <FormattedMessage
                        id='front-end'
                        defaultMessage='Front-End'
                    />
                </h4>
                <div className="skill">
                    <div>
                        <img alt="HTML" className="icons-skils" src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg" title="HTML"/>
                        <h5>HTML</h5>
                    </div>
                    <div>
                        <img alt="CSS" className="icons-skils" src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg" title="CSS"/>
                        <h5>CSS</h5>
                    </div>
                    <div>
                        <img alt="JavaScript" className="icons-skils" src="https://logosdownload.com/logo/javascript-logo-big.png" title="JavaScript"/>
                        <h5>JavaScript</h5>
                    </div>
                    <div>
                        <img alt="Sass" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg" title="Sass"/>
                        <h5>Sass</h5>
                    </div>
                    <div>
                        <img alt="Bootstrap" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg" title="Bootstrap"/>
                        <h5>Bootstrap</h5>
                    </div>
                    <div>
                        <img alt="Tailwindcss" className="icons-skils" src="https://static-00.iconduck.com/assets.00/tailwind-css-icon-2048x1229-u8dzt4uh.png" title="Tailwindcss"/>
                        <h5>Tailwindcss</h5>
                    </div>
                   {/* <div>
                        <img alt="jQuery" className="icons-skils" src="https://seeklogo.com/images/J/jquery-logo-CFE6ECE363-seeklogo.com.png" title="jQuery"/>
                        <h5>jQuery</h5>
</div>*/}
                    <div>
                        <img alt="React.Js" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" title="React.Js"/>
                        <h5>React.Js</h5>
                    </div>
                   {/* <div>
                        <img alt="Asp.Net" className="icons-skils" src="https://logodix.com/logo/1796970.png" title="Asp.Net"/>
                        <h5>Asp.Net</h5>
</div>*/}
                </div>
                <h4>
                    <FormattedMessage
                        id='back-end'
                        defaultMessage='Back-End'
                    />
                </h4>
                <div className="skill">
                    <div>
                        <img alt="Java" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg" title="Java"/>
                        <h5>Java</h5>
                    </div>
                   {/* <div>
                        <img alt="C#" className="icons-skils" src="https://seeklogo.com/images/C/c-logo-A44DB3D53C-seeklogo.com.png" title="C#"/>
                        <h5>C#</h5>
</div>*/}
                    
                </div>
                <h4>
                    <FormattedMessage
                        id='java-tools'
                        defaultMessage='Java Tools'
                    />
                </h4>
                <div className="skill">
                    <div>
                        <img alt="Selenium" className="icons-skils" src="https://static-00.iconduck.com/assets.00/selenium-icon-2048x1986-3fr2ikf8.png" title="Selenium"/>
                        <h5>Selenium</h5>
                    </div>
                    <div>
                        <img alt="Junit" className="icons-skils" src="https://www.opencodez.com/wp-content/uploads/2019/04/Junit-1.png" title="Junit"/>
                        <h5>Junit</h5>
                    </div>
                    <div>
                        <img alt="TestNG" className="icons-skils" src="https://static.javatpoint.com/tutorial/testng/images/testng-tutorial.png" title="TestNG"/>
                        <h5>TestNG</h5>
                    </div>
                    <div>
                        <img alt="Jenkins" className="icons-skils" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Jenkins_logo.svg/339px-Jenkins_logo.svg.png?20120629215426" title="Jenkins"/>
                        <h5>Jenkins</h5>
                    </div>
                    <div>
                        <img alt="Cucumber" className="icons-skils" src="https://seeklogo.com/images/C/cucumber-logo-D727C551CE-seeklogo.com.png" title="Cucumber"/>
                        <h5>Cucumber</h5>
                    </div>
                    <div>
                        <img alt="Maven" className="icons-skils" src="https://www.svgrepo.com/show/353400/apache.svg" title="Maven"/>
                        <h5>Maven</h5>
                    </div>
                </div>
                <h4>
                    <FormattedMessage
                        id='databases'
                        defaultMessage='Databases'
                    />
                </h4>
                <div className="skill">
                    <div>
                        <img alt="PsostgreSQL" className="icons-skils" src="https://static.fragment.co.jp/wp-content/uploads/2016/01/20151202194604-2.png" title="PsostgreSQL" />
                        <h5>PsostgreSQL</h5>
                    </div>
                    <div>
                        <img alt="MySQL" className="icons-skils" src="https://cdn-icons-png.flaticon.com/512/528/528260.png" title="MySQL" />
                        <h5>MySQL</h5>
                    </div>
                    <div>
                        <img alt="Microsoft SQL Server" className="icons-skils" src="https://uploads-ssl.webflow.com/62d5695206896eeec18fc636/63e6a9c0ac8f176d1d339210_sql-logo.png" title="Microsoft SQL Server" />
                        <h5>Microsoft SQL Server</h5>
                    </div>
                    <div>
                        <img alt="Mongodb" className="icons-skils" src="https://www.svgrepo.com/download/331488/mongodb.svg" title="Mongodb" />
                        <h5>MongoDB</h5>
                    </div>
                </div>
                <h4>
                    <FormattedMessage
                        id='clouds'
                        defaultMessage='Clouds'
                    />
                </h4>
                <div className="skill">
                    <div>
                        <img alt="GitHub" className="icons-skils" src="https://pngimg.com/d/github_PNG90.png" title="GitHub" />
                        <h5>GitHub</h5>
                    </div>
                    <div>
                        <img alt="AWS" className="icons-skils" src="https://uploads-ssl.webflow.com/619811f15cf1e46af063c233/620dc6482e585bbdb5feff64_aws-logo.png" title="AWS" />
                        <h5>AWS</h5>
                    </div>
                    <div>
                        <img alt="Docker" className="icons-skils" src="https://www.docker.com/wp-content/uploads/2022/03/Moby-logo.png" title="Docker" />
                        <h5>Docker</h5>
                    </div>
                </div>
                <h4>
                    <FormattedMessage
                        id='tools'
                        defaultMessage='Tools'
                    />
                </h4>
                <div className="skill">
                    <div>
                        <img alt="Intellij" className="icons-skils" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/IntelliJ_IDEA_Icon.svg/2048px-IntelliJ_IDEA_Icon.svg.png" title="Intellij" />
                        <h5>Intellij</h5>
                    </div>
                   {/* <div>
                        <img alt="Eclipse " className="icons-skils" src="https://www.pinclipart.com/picdir/big/520-5207988_transparent-eclipse-clip-art-free-png-download.png" title="Eclipse " />
                        <h5>Eclipse </h5>
</div>*/}
                    <div>
                        <img alt="Visual Studio Code" className="icons-skils" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Visual_Studio_Code_1.18_icon.svg/1200px-Visual_Studio_Code_1.18_icon.svg.png" title="Visual Studio Code" />
                        <h5>Visual Studio Code</h5></div>
                    <div></div>
                    <div>
                        <img alt="Jira" className="icons-skils" src="https://seeklogo.com/images/J/jira-logo-FD39F795A7-seeklogo.com.png" title="Jira" />
                        <h5>Jira</h5>
                    </div>
                    {/*<div>
                        <img alt="NetBeans" className="icons-skils" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Apache_NetBeans_Logo.svg/444px-Apache_NetBeans_Logo.svg.png" title="NetBeans" />
                        <h5>NetBeans</h5></div>
                    <div>
                        <img alt="Photoshop" className="icons-skils" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Adobe_Photoshop_CC_icon.svg/2101px-Adobe_Photoshop_CC_icon.svg.png" title="Photoshop" />
                        <h5>Photoshop</h5>
                    </div>
                    <div>
                        <img alt="Adobe XD" className="icons-skils" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Adobe_XD_CC_icon.svg/1200px-Adobe_XD_CC_icon.svg.png" title="Adobe XD" />
                        <h5>Adobe XD</h5>
</div>*/}
                </div>
            </div>
        </div>
    </section>
);

export default React.memo(About);