import React from 'react';
import '../../pages/Project/ProjectPage.css'
import { Link } from 'react-router-dom';
import { ButtomGet } from '../ButtomGet/ButtomGet';
import gaotek from '../../paperwork/certificates/Tech_Digital_Marketing_Internship.pdf'
import dMarketing from '../../paperwork/certificates/Digital_Marketing.pdf';
import aILiteracy from '../../paperwork/certificates/Artifical_Intelligence_Literacy.pdf';
import fullStackQA from '../../paperwork/certificates/FullStack_Automation_Test_Engineering.pdf';
import techSupport from '../../paperwork/certificates/Tech_Support.pdf';
import istqbFoundation from '../../paperwork/certificates/istqb-foundation-level-_ctfl.pdf';


/* Multi idioma */
import { FormattedMessage } from 'react-intl';

/* Swiper */
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";

/* Img */
const proyectImg = require.context('../../img/certificates', true);

const Certificate = () => {
    return (
        <section className="proyectos" id="cetificates">
            <h2 className="heading">
                <FormattedMessage
                    id='certificates'
                    defaultMessage='certificates'
                />
            </h2>
           
      
                
            <div className="proyect-site" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <Swiper
                    spaceBetween={30}
                    loop={true}
                    grabCursor={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                    }}
                    className='proyectos-slider mySwiper'
                >

               
          

{/* Tech Digital Marketing Internship*/}
                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./Tech_Digital_Marketing_Internship.png`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='gaotekcourseback'
                        defaultMessage='Tech Digital Marketing Internship '/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='gaotekcourseback1'
                        defaultMessage=' Tech Digital Marketing Internship'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span>  Tech Digital Marketing Internship
                            </p>
                            <a href={gaotek} className="custom-btn btn"download="Tech_Digital_Marketing_Internship.pdf"  target="_blank" rel="noopener noreferrer"><span> <FormattedMessage
                    id='Download'
                    defaultMessage='Download'
                />          
                </span></a>
                            <a href="https://gaotek.com/" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide> 
{/* Digital Marketing*/}

                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./Digital_Marketing.png`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='dMarketing'
                        defaultMessage='Digital Marketing'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='dMarketing'
                        defaultMessage='Digital Marketing'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> Digital Marketing
                            </p>
                            <a href={dMarketing}className="custom-btn btn"download="Digital_Marketing.pdf"  target="_blank" rel="noopener noreferrer"><span> <FormattedMessage
                    id='Download'
                    defaultMessage='Download'
                />          
                </span></a>
                            <a href="https://gaotek.com/" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide> 
                   
{/*Artifical Intelligence Literacy*/}

<SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./Digital_Marketing.png`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='aILiteracy'
                        defaultMessage='Artifical Intelligence Literacy'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='aILiteracy'
                        defaultMessage='Artifical Intelligence Literacy'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> Artifical Intelligence Literacy
                            </p>
                            <a href={aILiteracy} className="custom-btn btn"download="Digital_Marketing.pdf"  target="_blank" rel="noopener noreferrer"><span> <FormattedMessage
                    id='Download'
                    defaultMessage='Download'
                />          
                </span></a>
                            <a href="https://gaotek.com/" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide> 


{/*Full Stack Automation Test Engineering*/}

<SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./Full_Stack_Automation_Test_Engineering.png`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='fullStackQA'
                        defaultMessage='Full Stack Automation Test Engineering'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='fullStackQA'
                        defaultMessage='Full Stack Automation Test Engineering'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> Full Stack Automation Test Engineering
                            </p>
                            <a href={fullStackQA} className="custom-btn btn"download="FullStack_Automation_Test_Engineering.pdf"  target="_blank" rel="noopener noreferrer"><span> <FormattedMessage
                    id='Download'
                    defaultMessage='Download'
                />          
                </span></a>
                            <a href="https://www.techproeducation.com.tr/kurs/yazilim-test-uzmani" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide> 

                    {/* Tech Support*/}

                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./AI_Literacy.png`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='techSupport'
                        defaultMessage='Tech Support'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='techSupport'
                        defaultMessage='Tech Support'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> Tech Support
                            </p>
                            <a href={techSupport} className="custom-btn btn"download="Tech_Support.pdf"  target="_blank" rel="noopener noreferrer"><span> <FormattedMessage
                    id='Download'
                    defaultMessage='Download'
                />          
                </span></a>
                            <a href="https://www.techproeducation.com.tr/kurs/data-science?gad_source=1&gclid=Cj0KCQjw9vqyBhCKARIsAIIcLMFvR6ozt8SxCRvXFGIGKuV5uBU7tx8KGzcnJYretJ7XjYtJER8ClSQaAoRdEALw_wcB" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide> 


{/* ISTQB Foundation Level CTFL*/}

<SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./istqb_foundation_level_ctfl.png`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='istqbFoundation'
                        defaultMessage='ISTQB Foundation Level CTFL'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='istqbFoundation'
                        defaultMessage='ISTQB Foundation Level CTFL'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> ISTQB Foundation Level CTFL
                            </p>
                        <a href={istqbFoundation} className="custom-btn btn"download="istqb-foundation-level-_ctfl.pdf"  target="_blank" rel="noopener noreferrer"><span> <FormattedMessage
                    id='Download'
                    defaultMessage='Download'
                />          
                </span></a>
                            <a href="https://app.diplomasafe.com/en-US/diploma/dab02f4efc8288160e99d317c20e4d0cf7aac55a8" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide> 


                   
                </Swiper>

             {/*    <div className="swiper-pagination"></div>
             </div>
             <Link className="custom-btn btn-codigo portafolio-btn" to="/project">
                <FormattedMessage
                    id='btn-more-projects'
                    defaultMessage='More projects'
                />
            </Link> 
            <div className='portafolio-btn'>
                <Link to="/Certificate">
                    <ButtomGet/>
                </Link> 
             </div>*/}
                </div>
        </section>

    )
};
export default React.memo(Certificate);