import React from 'react';
import '../../pages/Contact/ContactPage.css'
/* TypedJs */
import Typical from 'react-typical';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

const Contact = () => (

    <section className="contactos" id="contactos">

        <h2 className="heading">
            <FormattedMessage
                id='contact'
                defaultMessage='Contact'
            />
        </h2>
        <h3 className="titulo" data-aos="fade-left" data-aos-delay="300">
            <FormattedMessage
                id='contact-info'
                defaultMessage='Contact me by: '
            />
            <Typical
                className="site-contacto"
                loop={Infinity}
                wrapper="b"
                steps={[
                    'Mail', 1500
                    ,
                    'WhatsApp', 1500,
                    'Instragram', 1500,
                    'Telegram', 1500,
                    'Linkedin', 1500,
                    'Github', 1500,
                    'Facebook', 1500,
                    'Twitter', 1500,
                    'Youtube', 1500,
                    'Medium', 1500,
                ]}
            />

        </h3>

        <div className="icons">
         
            <a href="https://www.linkedin.com/in/husnukaya/" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                 
                    <span></span>
                    <span></span>
                    <span className="fab fa-linkedin-in"></span>
                    <span></span>
                    
                </div>
                <div className="text">
                    Linkedin
                </div>
            </a>
            <a href="https://wa.me/+905518458103" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                
                    <span></span>
                    <span></span>
                    <span className="fab fa-whatsapp"></span>
                    <span></span>
                    
                </div>
                <div className="text">
                    Whatsapp
                </div>
            </a>
            <a href="https://t.me/+905518458103" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                  
                    <span></span>
                    <span></span>
                    <span className="fab fa-telegram"></span>
                    <span></span>
                    
                </div>
                <div className="text">
                    telegram
                </div>
            </a>
            <a href="" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                 
                    <span></span>
                    <span></span>
                    <span className="fab fa-instagram"></span>
                    <span></span>
                    
                </div>
                <div className="text">
                    Instagram
                </div>
            </a>
            <a href="" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                   
                    <span></span>
                    <span></span>
                    <span className="fab fa-facebook"></span>
                    <span></span>
                   
                </div>
                <div className="text">
                    Facebook
                </div>
            </a>
            <a href="mailto:husnukaya41@gmail.com" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                   
                    <span></span>
                    <span></span>
                    <span className="fab fas fa-envelope"></span>
                    <span></span>
                   
                </div>
                <div className="text">
                    Mail
                </div>
            </a>
            <a href="https://www.youtube.com/@YusufTekmil" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                    
                    <span></span>
                    <span></span>
                    <span className="fab fa-youtube"></span>
                    <span></span>
                    
                </div>
                <div className="text">
                    Youtube
                </div>
            </a>
            <a href="" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                    
                    <span></span>
                    <span></span>
                    <span className="fab fa-twitter"></span>
                    <span></span>
                   
                </div>
                <div className="text">
                    Twitter
                </div>
            </a>
            <a href="https://github.com/Husnukaya" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                    
                    <span></span>
                    <span></span>
                    <span className="fab fa-github-square"></span>
                    <span></span>
                   
                </div>
                <div className="text">
                    GitHub
                </div>
            </a>
            <a href="https://medium.com/@husnukaya" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                   
                    <span></span>
                    <span></span>
                    <span className="fab fa-medium"></span>
                    <span></span>
                    
                </div>
                <div className="text">
                Medium
                </div>
            </a>
        </div>
    </section>
);

export default React.memo(Contact);