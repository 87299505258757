import React from 'react';
import './AboutPage.css'

/* Componet */
import HeaderPage from '../../components/Header/HeaderPage';
import Footer from '../../components/Footer/Footer';
import ParticleBackground from "../../components/ParticlesBg/ParticleBackground";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import trresume from '../../paperwork/resume/tr-resume.pdf';
import gresume from '../../paperwork/resume/g-resume.pdf';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

/* Img */
import imgabout from '../../img/home.gif';

const About = () => {

  function readMore() {
    let btnHide = document.querySelector("#btn-hide");
    let parrafoActive = document.querySelector(".parrafo-active");

    parrafoActive.classList.toggle("show");

    if (parrafoActive.classList.contains("show")) {
      btnHide.innerHTML = "↑";
    } else {
      btnHide.innerHTML = "Read more";
    }
  }

  return (
    <div>

      <HeaderPage />

      <ParticleBackground />

      <main>
        <section className="sobre-mi-seccion " id="sobre-mi">
          <div className="sobre-mi-container ">
            <div className="sobre-mi-img-container">
              <img src={imgabout} alt="" className="sobre-mi-img" />
              
              <div className='btns_positions'>
                    <a href={trresume} target="_blank" rel="noopener noreferrer" download="cv.pdf" className="btn-codigo cv buttonDownload btn-p1">
                         <FormattedMessage
                            id='btn-tr-Resume'
                            defaultMessage='Turkey Resume'
                         />
                    </a>
                    <a href={gresume} target="_blank" rel="noopener noreferrer" download="cv.pdf" className="btn-codigo cv buttonDownload btn-p2">
                         <FormattedMessage
                            id='btn-tr-Resume'
                            defaultMessage='Global Resume'
                         />
                    </a>
                    </div>

           
            </div>
           
            <div className="sobre-mi-info">
              <p>
                <FormattedMessage
                  id='about-info-1'
                  defaultMessage=" "
                />
              </p>

              <div className="hide parrafo-active">
                <p>
                  <FormattedMessage
                    id='about-info-2'
                    defaultMessage=' '
                  />
                </p>
              </div>
              <div className="hide parrafo-active">
                <p>
                  <FormattedMessage
                    id='about-info-2-a'
                    defaultMessage=' '
                  />
                </p>
              </div>

              <div className="btn-info">
                <div className="custom-btn btn-codigo" id="btn-hide" onClick={readMore}><span>Read more</span></div>
              </div>
            </div>

          </div>

          <div className="skill-seccion">
            <h1 className="heading"> 
                  <FormattedMessage
                    id='about-skills-main'
                    defaultMessage='Skills'
                  /></h1>//TODO
            <div className="skill-container">
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="HTML" className="skills-img icon-li" src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg" title="HTML5" />
                <h2 className="skill-name">HTML</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-1'
                    defaultMessage='More than a year of professional experience in this language, allows me to perform without problems in the industry.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-up" data-aos-delay="300">
                <img alt="CSS" className="skills-img icon-li" src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg" title="CSS3" />
                <h2 className="skill-name">CSS</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-2'
                    defaultMessage='More than a year of work experience in this language, I use it daily to develop web designs and interfaces.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-right" data-aos-delay="300">
                <img alt="Js" className="skills-img icon-li" src="https://logosdownload.com/logo/javascript-logo-big.png" title="Java Script" />
                <h2 className="skill-name">Javascript</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-3'
                    defaultMessage='A year of experience using it in the front-end and back-end branch, allowed me to familiarize myself with the language, and include it in my work projects.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Sass" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg" title="Sass" />
                <h2 className="skill-name">Sass</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-4'
                    defaultMessage='One year using this preprocessor in the professional field, I include it in many of my projects since it allows me a better structured code.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-down" data-aos-delay="300">
                <img alt="Bootstrap" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg" title="Bootstrap" />
                <h2 className="skill-name">Bootstrap</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-5'
                    defaultMessage='Less than a year using this framework, even so, I used it in countless work projects, which allowed me to get to know it for the most part.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-down" data-aos-delay="300">
                <img alt="Tailwindcss" className="skills-img" src="https://static-00.iconduck.com/assets.00/tailwind-css-icon-2048x1229-u8dzt4uh.png" title="Tailwindcss" />
                <h2 className="skill-name">Tailwindcss</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-5'
                    defaultMessage='Less than a year using this framework, even so, I used it in countless work projects, which allowed me to get to know it for the most part.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-right" data-aos-delay="300">
                <img alt="Selenium" className="skills-img" src="https://static-00.iconduck.com/assets.00/selenium-icon-2048x1986-3fr2ikf8.png" title="Selenium" />
                <h2 className="skill-name">Selenium</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-6'
                    defaultMessage='Selenium is an umbrella project for a range of tools and libraries that enable and support the automation of web browsers.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-right" data-aos-delay="300">
                <img alt="React.js" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" title="React.Js" />
                <h2 className="skill-name">ReactJs</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-7'
                    defaultMessage='More than a year using this JavaScript framework, carrying out multiple personal and work projects.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-right" data-aos-delay="300">
                <img alt="Jenkins" className="skills-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Jenkins_logo.svg/339px-Jenkins_logo.svg.png?20120629215426" title="Jenkins" />
                <h2 className="skill-name">Jenkins</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-7'
                    defaultMessage='Jenkins is a self-contained, open source automation server which can be used to automate all sorts of tasks related to building, testing, and delivering or deploying software.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-up" data-aos-delay="300">
                <img alt="nodejs" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg" title="Java" />
                <h2 className="skill-name">Java</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-8'
                    defaultMessage='It is the cross-platform runtime environment that I use to make my web applications scalable.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Cucumber Framework" className="skills-img" src="https://seeklogo.com/images/C/cucumber-logo-D727C551CE-seeklogo.com.png" title="Cucumber Framework" />
                <h2 className="skill-name">Cucumber Framework</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-9'
                    defaultMessage='Cucumber is an open-source BDD test automation tool that uses plain language for writing test scenarios, enhancing communication between developers and business stakeholders. It supports various programming languages, making it versatile for different projects.'
                />
                </p>
              </div>
             { /*<div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Spring Boot" className="skills-img" src="https://images.contentstack.io/v3/assets/blt39790b633ee0d5a7/blt4f779d1b4fd496c5/647a2f42d88bb100a5216601/spring.webp" title="Spring Boot" />
                <h2 className="skill-name">Spring Boot</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-10'
                    defaultMessage='...'
                  />
                </p>
  </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Hibernate" className="skills-img" src="https://cdn.worldvectorlogo.com/logos/hibernate.svg" title="Hibernate" />
                <h2 className="skill-name">Hibernate</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-11'
                    defaultMessage='...'
                  />
                </p>
              </div>*/}
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Maven" className="skills-img" src="https://www.svgrepo.com/show/353400/apache.svg" title="Maven" />
                <h2 className="skill-name">Maven</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-12'
                    defaultMessage='...'
                  />
                </p>
              </div>
             
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="PsostgreSQL" className="skills-img" src="https://static.fragment.co.jp/wp-content/uploads/2016/01/20151202194604-2.png" title="PsostgreSQL" />
                <h2 className="skill-name">PsostgreSQL</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-13'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="MySQL" className="skills-img" src="https://cdn-icons-png.flaticon.com/512/528/528260.png" title="MySQL" />
                <h2 className="skill-name">MySQL</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-14'
                    defaultMessage='...'
                  />
                </p>
              </div> 
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Microsoft SQL Server" className="skills-img" src="https://uploads-ssl.webflow.com/62d5695206896eeec18fc636/63e6a9c0ac8f176d1d339210_sql-logo.png" title="Microsoft SQL Server" />
                <h2 className="skill-name">Microsoft SQL Server</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-15'
                    defaultMessage='...'
                  />
                </p>
              </div>
            {/*  <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Mongodb" className="skills-img" src="https://www.svgrepo.com/download/331488/mongodb.svg" title="Mongodb" />
                <h2 className="skill-name">Mongodb</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-16'
                    defaultMessage='...'
                  />
                </p>
              </div>
              
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="AWS" className="skills-img" src="https://uploads-ssl.webflow.com/619811f15cf1e46af063c233/620dc6482e585bbdb5feff64_aws-logo.png" title="AWS" />
                <h2 className="skill-name">AWS</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-17'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Docker" className="skills-img" src="https://www.docker.com/wp-content/uploads/2022/03/Moby-logo.png" title="Docker" />
                <h2 className="skill-name">Docker</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-18'
                    defaultMessage='...'
                  />
                </p>
            </div>*/}
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Intellij" className="skills-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/IntelliJ_IDEA_Icon.svg/2048px-IntelliJ_IDEA_Icon.svg.png" title="Intellij" />
                <h2 className="skill-name">Intellij</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-19'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Jira" className="skills-img" src="https://seeklogo.com/images/J/jira-logo-FD39F795A7-seeklogo.com.png" title="Jira" />
                <h2 className="skill-name">Jira</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-20'
                    defaultMessage='Jira is a project management and issue tracking tool developed by Atlassian. It helps teams plan, track, and manage software development projects with features like bug tracking, agile project management, and customizable workflows.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Visual Studio Code" className="skills-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Visual_Studio_Code_1.18_icon.svg/1200px-Visual_Studio_Code_1.18_icon.svg.png" title="Visual Studio Code" />
                <h2 className="skill-name">Visual Studio Code</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-21'
                    defaultMessage='...'
                  />
                </p>
              </div>
              {/*<div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="NetBeans" className="skills-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Apache_NetBeans_Logo.svg/444px-Apache_NetBeans_Logo.svg.png" title="NetBeans" />
                <h2 className="skill-name">NetBeans</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-22'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Spring Tools 4" className="skills-img" src="https://spring.io/img/projects/spring-tool.svg" title="Spring Tools 4" />
                <h2 className="skill-name">Spring Tools 4</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-23'
                    defaultMessage='...'
                  />
                </p>
          </div>*/}
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="GitHub" className="skills-img" src="https://pngimg.com/d/github_PNG90.png" title="GitHub" />
                <h2 className="skill-name">GitHub</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-24'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="TestNG" className="skills-img" src="https://static.javatpoint.com/tutorial/testng/images/testng-tutorial.png" title="TestNG" />
                <h2 className="skill-name">TestNG</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-25'
                    defaultMessage='TestNG is a testing framework for Java that helps in automating tests for your software applications. It allows you to write and organize test cases, execute them efficiently, and generate detailed test reports. TestNG supports various testing scenarios like unit testing, integration testing, and end-to-end testing'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Junit" className="skills-img" src="https://www.opencodez.com/wp-content/uploads/2019/04/Junit-1.png" title="Junit" />
                <h2 className="skill-name">Junit</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-26'
                    defaultMessage='JUnit is a unit testing framework to write and run repeatable automated tests on Java. It provides a robust environment to write, organize, and execute automated tests, ensuring code reliability and repeatability. Its user-friendly annotations and assert methods facilitate the development and running of test cases, making it a foundational tool for Java developers focusing on quality assurance and test-driven development.'
                  />
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      <ScrollToTop />

      <Footer />

    </div>
  )
}
export default About;