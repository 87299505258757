import React from 'react';

/* Componet */
import About from './About';
import Service from './Service';
import Project from './Project';
import Contact from './Contact';
import Certificate from './Certificate';

const Main = () => (
    <main>
        <About />  
        
        <Certificate/>      

       {/* <Service />
        
<Project />*/}

        <Contact />
    </main>
);

export default Main;